/**
* Template Name: ZenBlog - v1.3.0
* Template URL: https://bootstrapmade.com/zenblog-bootstrap-blog-template/
* Author: BootstrapMade.com
* License: https:///bootstrapmade.com/license/
*/

/**
* Check out variables.css for easy customization of colors, typography, and other repetitive properties
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: rgba(var(--color-primary-rgb), 1);
}

a {
  color: var(--color-links);
  text-decoration: none;
}

a:hover {
  color: var(--color-links-hover);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

#main {
  margin-top: 90px;
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 40px 0;
  overflow: hidden;
}

.section-header {
  border-bottom: 2px solid var(--color-black);
}

.section-header h2 {
  font-size: 50px;
  color: var(--color-black);
}

.section-header p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Page title
--------------------------------------------------------------*/
.page-title {
  font-size: 70px;
  color: var(--color-black);
}

/* Form Input Fieldds */
.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-black);
}

.btn:active,
.btn:focus {
  outline: none;
}

.btn.btn-primary {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  /* background: #f2f2f2; */
}

/*--------------------------------------------------------------
# Posts
--------------------------------------------------------------*/
.post-entry-1 {
  margin-bottom: 30px;
}

.post-entry-1 img {
  margin-bottom: 30px;
}

.post-entry-1 h2 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  font-weight: 500;
}

.post-entry-1 h2 a {
  color: var(--color-black);
}

.post-entry-1.lg h2 {
  font-size: 40px;
  line-height: 1;
}

.post-meta {
  font-size: 11px;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-secondary);
  color: rgba(var(--color-black-rgb), 0.4);
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .custom-border {
    border: none !important;
  }
}

.author .photo {
  margin-right: 10px;
}

.author .photo img {
  width: 40px;
  border-radius: 50%;
  margin-bottom: 0;
}

.author .name h3 {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: var(--font-secondary);
}

.trending {
  border: 1px solid rgba(var(--color-black-rgb), 0.1);
}

.trending>h3 {
  color: var(--color-black);
  padding: 20px;
  border-bottom: 1px solid rgba(var(--color-black-rgb), 0.1);
}

.trending .trending-post {
  padding: 0;
  margin: 0;
}

.trending .trending-post li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
}

.trending .trending-post li a {
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(var(--color-black-rgb), 0.1);
  position: relative;
  overflow: hidden;
}

.trending .trending-post li a .number {
  position: absolute;
  z-index: -1;
  font-size: 5rem;
  left: -10px;
  top: -20px;
  font-weight: 700;
  color: rgba(var(--color-black-rgb), 0.05);
}

.trending .trending-post li a h3 {
  font-size: 18px;
  color: rgba(var(--color-black-rgb), 0.9);
}

.trending .trending-post li a .author {
  color: rgba(var(--color-black-rgb), 0.7);
  font-weight: 500;
}

.trending .trending-post li a:hover h3 {
  color: rgba(var(--color-black-rgb), 1);
}

.trending .trending-post li:last-child a {
  border-bottom: none;
}

.post-entry-2 {
  margin-bottom: 30px;
}

.post-entry-2 .post-meta {
  font-size: 11px;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-secondary);
  color: rgba(var(--color-black-rgb), 0.4);
  margin-bottom: 10px;
}

.post-entry-2 .author {
  color: rgba(var(--color-black-rgb), 0.7);
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
}

.post-entry-2 .thumbnail {
  flex: 0 0 65%;
}

@media (max-width: 960px) {
  .post-entry-2 .thumbnail {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

.post-entry-2.half .thumbnail {
  flex: 0 0 50%;
}

@media (max-width: 768px) {
  .post-entry-2.half .thumbnail {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

.post-entry-2.small-img .thumbnail {
  flex: 0 0 30%;
}

@media (max-width: 768px) {
  .post-entry-2.small-img .thumbnail {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

.img-bg {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center center;
}

@media (max-width: 768px) {
  .img-bg {
    height: 400px;
  }
}

.img-bg:before {
  position: absolute;
  content: "";
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  top: 0;
}

.img-bg .img-bg-inner {
  position: relative;
  z-index: 2;
  max-width: 700px;
  position: relative;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .img-bg .img-bg-inner {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 50px;
  }
}

.img-bg .img-bg-inner h2,
.img-bg .img-bg-inner p {
  color: var(--color-white);
}

@media (max-width: 500px) {
  .img-bg .img-bg-inner p {
    display: none;
  }
}

.custom-swiper-button-next,
.custom-swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media (max-width: 768px) {

  .custom-swiper-button-next,
  .custom-swiper-button-prev {
    display: none;
  }
}

.custom-swiper-button-next span,
.custom-swiper-button-prev span {
  font-size: 20px;
  color: rgba(var(--color-white-rgb), 0.7);
  transition: 0.3s all ease;
}

.custom-swiper-button-next:hover span,
.custom-swiper-button-next:focus span,
.custom-swiper-button-prev:hover span,
.custom-swiper-button-prev:focus span {
  color: rgba(var(--color-white-rgb), 1);
}

.custom-swiper-button-next {
  right: 40px;
}

.custom-swiper-button-prev {
  left: 40px;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: rgba(var(--color-white-rgb), 0.8);
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgba(var(--color-white-rgb), 1);
}

.more {
  font-family: var(--font-secondary);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}

.more:before {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-black);
}

.post-content {
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 768px) {
  .post-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.post-content .firstcharacter {
  float: left;
  font-family: Georgia;
  font-size: 75px;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

.post-content figure {
  position: relative;
  left: 50%;
  min-width: auto;
  transform: translateX(-50%);
}

@media (max-width: 1255px) {
  .post-content figure {
    min-width: auto;
    left: auto !important;
    transform: none;
  }
}

.post-content figure figcaption {
  font-family: var(--font-secondary);
  font-size: 14px;
  padding: 10px 0 0 0;
}

.aside-title,
.category-title {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 30px;
  border-bottom: 2px solid var(--color-black);
}

.category-title {
  border-bottom: none;
}

.custom-pagination a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-family: var(--font-secondary);
  margin: 5px;
  transition: 0.3s all ease;
}

.custom-pagination a.active {
  background: var(--color-black);
  color: var(--color-white);
}

.custom-pagination a.active:hover {
  background: rgba(var(--color-black-rgb), 0.9);
}

.custom-pagination a:hover {
  background: rgba(var(--color-black-rgb), 0.1);
}

.custom-pagination a.prev,
.custom-pagination a.next {
  width: auto !important;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-pagination a.prev:hover,
.custom-pagination a.next:hover {
  background: rgba(var(--color-black-rgb), 0.1);
}

/* custom tab nav on sidebar */
.aside-block {
  margin-bottom: 30px;
}

.aside-block .custom-tab-nav .nav-item {
  display: inline-block;
}

.aside-block .custom-tab-nav .nav-item button {
  color: var(--color-black);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  margin-right: 20px;
}

.aside-block .custom-tab-nav .nav-item button.active {
  background-color: var(--color-black) !important;
  background-color: transparent !important;
  color: var(--color-black);
  border-bottom: 2px solid var(--color-black);
}

.link-video {
  position: relative;
}

.link-video span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-color: rgba(var(--color-black-rgb), 0.2);
  color: var(--color-white);
}

.aside-links li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--color-black-rgb), 0.1);
}

.aside-links li a {
  display: block;
  color: rgba(var(--color-black-rgb), 0.7);
}

.aside-links li a:hover,
.aside-links li a:focus {
  color: rgba(var(--color-black-rgb), 1);
}

.aside-tags li {
  display: inline-block;
}

.aside-tags li a {
  display: inline-block;
  color: rgba(var(--color-black-rgb), 0.7);
  padding: 7px 10px;
  border: 1px solid rgba(var(--color-black-rgb), 0.1);
  margin-bottom: 3px;
  transition: 0.3s all ease;
}

.aside-tags li a:hover,
.aside-tags li a:focus {
  color: rgba(var(--color-black-rgb), 1);
  border: 1px solid rgba(var(--color-black-rgb), 0.5);
}

/*--------------------------------------------------------------
# Comments
--------------------------------------------------------------*/
.comment {
  /* Font not working in <textarea> for this version of bs */
}

.comment .avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.comment .avatar-img,
.comment .avatar-initials,
.comment .avatar-placeholder {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.comment .avatar-img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.comment .avatar-initials {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  line-height: 0;
  background-color: rgba(var(--color-black-rgba), 0.1);
}

.comment .avatar-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(var(--color-black-rgba), 0.1) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='%23fff' d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'/%3e%3c/svg%3e") no-repeat center/1.75rem;
}

.comment .avatar-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20%;
  height: 20%;
  display: block;
  background-color: rgba(var(--color-black-rgba), 0.1);
  border-radius: 50%;
}

.comment .avatar-group {
  display: inline-flex;
}

.comment .avatar-group .avatar+.avatar {
  margin-left: -0.75rem;
}

.comment .avatar-group .avatar:hover {
  z-index: 1;
}

.comment .avatar-sm,
.comment .avatar-group-sm>.avatar {
  width: 2.125rem;
  height: 2.125rem;
  font-size: 1rem;
}

.comment .avatar-sm .avatar-placeholder,
.comment .avatar-group-sm>.avatar .avatar-placeholder {
  background-size: 1.25rem;
}

.comment .avatar-group-sm>.avatar+.avatar {
  margin-left: -0.53125rem;
}

.comment .avatar-lg,
.comment .avatar-group-lg>.avatar {
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
}

.comment .avatar-lg .avatar-placeholder,
.comment .avatar-group-lg>.avatar .avatar-placeholder {
  background-size: 2.25rem;
}

.comment .avatar-group-lg>.avatar+.avatar {
  margin-left: -1rem;
}

.comment .avatar-light .avatar-indicator {
  box-shadow: 0 0 0 2px rgba(var(--color-white-rgba), 0.75);
}

.comment .avatar-group-light>.avatar {
  box-shadow: 0 0 0 2px rgba(var(--color-white-rgba), 0.75);
}

.comment .avatar-dark .avatar-indicator {
  box-shadow: 0 0 0 2px rgba(var(--color-black-rgba), 0.25);
}

.comment .avatar-group-dark>.avatar {
  box-shadow: 0 0 0 2px rgba(var(--color-black-rgba), 0.25);
}

.comment textarea {
  font-family: inherit;
}

.comment .comment-replies-title,
.comment .comment-title {
  text-transform: uppercase;
  color: var(--color-black) !important;
  letter-spacing: 0.1rem;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
}

.comment .comment-meta .text-muted,
.comment .reply-meta .text-muted {
  font-family: var(--font-secondary);
  font-size: 12px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-item {
  text-align: center;
}

.contact .info-item i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: var(--color-primary);
}

.contact .info-item address,
.contact .info-item p {
  margin-bottom: 0;
}

.contact .info-item h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--color-primary);
}

.contact .info-item a:hover {
  color: var(--color-primary);
}

@media (min-width: 768px) {
  .contact .info-item-borders {
    border-left: 1px solid rgba(var(--color-secondary-rgb), 0.2);
    border-right: 1px solid rgba(var(--color-secondary-rgb), 0.2);
  }
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(var(--color-black-rgb), 0.1);
  padding: 30px;
  background: var(--color-white);
}

@media (max-width: 640px) {
  .contact .php-email-form {
    padding: 20px;
  }
}

.contact .php-email-form .error-message {
  display: none;
  color: var(--color-white);
  background: var(--color-red);
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: var(--color-white);
  background: var(--color-green);
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: var(--color-white);
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--color-green);
  border-top-color: var(--color-white);
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: var(--color-primary);
}

.contact .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  overflow: hidden;
  background: rgba(var(--color-black-rgb), 0.9);
  font-size: 16px;
  color: rgba(var(--color-white-rgb), 0.7);
}

.footer .footer-content {
  padding: 60px 0;
}

.footer a.footer-link-more {
  color: rgba(var(--color-white-rgb), 0.7);
  display: inline-block;
  position: relative;
}

.footer a.footer-link-more:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--color-white);
}

.footer a.footer-link-more:hover {
  color: rgba(var(--color-white-rgb), 1);
}

.footer .footer-heading {
  color: var(--color-white);
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 18px;
}

.footer .footer-blog-entry li {
  margin-bottom: 20px;
  display: block;
}

.footer .footer-blog-entry li a .post-meta {
  font-size: 10px;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--font-secondary);
  color: rgba(var(--color-white-rgb), 0.4);
  margin-bottom: 0px;
}

.footer .footer-blog-entry li a img {
  flex: 0 0 50px;
  width: 50px;
}

.footer .footer-links li {
  margin-bottom: 10px;
}

.footer .footer-links li a {
  color: rgba(var(--color-white-rgb), 0.7);
}

.footer .footer-links li a:hover,
.footer .footer-links li a:focus {
  color: rgba(var(--color-white-rgb), 1);
}

.footer .footer-legal {
  background: var(--color-black);
  padding: 40px 0;
}

.footer .footer-legal .social-links a {
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--color-white-rgb), 0.09);
  border-radius: 50%;
  color: var(--color-white);
  line-height: 40px;
}

.footer .footer-legal .social-links a:hover {
  background-color: rgba(var(--color-white-rgb), 0.2);
}

.footer .copyright strong {
  font-weight: 400;
}

.footer .credits {
  padding-top: 6px;
  font-size: 13px;
}

.footer .credits a {
  color: var(--color-white);
}

.speed-dial {
  background-color: black;
}

.commenter {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.number {
  z-index: -1;
  font-size: 5rem;
  font-weight: 900;
  color: rgba(var(--color-black-rgb), 0.2);
}